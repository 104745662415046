import { getDocs, limit, orderBy, query, where } from 'firebase/firestore'

import { Profile } from '@/types/user'
import {
  getCollection,
  createCollectionDoc,
  updateCollectionDoc,
  deleteCollectionDoc,
  readCollectionDocById,
  readCollectionDocs,
} from '@/utils/firebase'
import { sendTelegramMessage } from '@/utils/telegram'

const profileCollectionRef = getCollection('profiles')

export const createProfile = createCollectionDoc<Profile>({
  collectionRef: profileCollectionRef,
})

export const updateProfile = updateCollectionDoc<Profile>({
  collectionRef: profileCollectionRef,
})

export const updateProfileByUid = async ({
  uid,
  doc,
  withNotification = false,
}: {
  uid: string
  doc: Profile
  withNotification?: boolean
}) => {
  const profile = await readProfileByUid({ uid })
  await updateCollectionDoc<Profile>({
    collectionRef: profileCollectionRef,
  })({
    id: profile.id,
    doc,
    withNotification,
  })
}

export const deleteProfile = deleteCollectionDoc({
  collectionRef: profileCollectionRef,
})

export const deleteProfileByUid = async ({
  uid,
  withNotification = false,
}: {
  uid: string
  withNotification?: boolean
}) => {
  const profile = await readProfileByUid({ uid })
  await deleteCollectionDoc({
    collectionRef: profileCollectionRef,
  })({
    id: profile.id,
    withNotification,
  })
}

export const readProfileById = readCollectionDocById<Profile>({
  collectionRef: profileCollectionRef,
})

export const readProfiles = readCollectionDocs<Profile>({
  collectionRef: profileCollectionRef,
  queryConstraints: [orderBy('uid', 'asc')],
})

export const readProfileByUid = async ({
  uid,
  withNotification = false,
}: {
  uid: string
  withNotification?: boolean
}) => {
  const profiles: Profile[] = []
  const q = query(profileCollectionRef, where('uid', '==', uid), limit(1))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((_doc) => {
    const _profile = {
      id: _doc.id,
      ..._doc.data(),
    } as Profile
    profiles.push(_profile)
  })

  if (withNotification) {
    await sendTelegramMessage({
      topic: '단일 재화 조회 / readTreasureByEnName',
      message: `조회 Query : ${JSON.stringify({ uid })}
조회 결과(json)
${JSON.stringify(profiles, null, 4)}`,
    })
  }

  const user = profiles?.length ? profiles[0] : null

  return user
}
