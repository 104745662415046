import { useRouter } from 'next/router'

import Img from '@/components/common/Img'
import { TREASURE_TYPE_MAP } from '@/constants/ckk/treasure'
import { TreasureType } from '@/types/ckk'

interface Props {
  type: TreasureType
  className?: string
}
function TreasureTypeItem({ type, className }: Props) {
  const { locale } = useRouter()
  const { name, image } = TREASURE_TYPE_MAP[type]
  const localeName = name[locale]

  return (
    <Img
      alt={localeName}
      className={className}
      src={image}
      style={{
        verticalAlign: 'middle',
        width: 70,
        height: 23,
      }}
    />
  )
}

export default TreasureTypeItem
