import Link from 'next/link'
import { useRouter } from 'next/router'

import { forwardRef } from 'react'

import { faEye, faComment, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag } from 'antd'

import ProfileItem from '@/components/ckk/items/ProfileItem'
import { ARTICLE_CATEGORY_MAP } from '@/components/ckk/WriteBoard'
import { Article } from '@/types/ckk'

interface Props {
  article: Article
  isFirst?: boolean
  isLast?: boolean
}

function ArticleCardItem({ article, isLast }: Props, ref) {
  const { locale } = useRouter()

  return (
    <div ref={ref}>
      <Link
        key={article.id}
        className={`ml-4 ${
          isLast ? 'mr-4' : '-mr-1'
        } flex rounded-lg bg-white px-4 py-3 shadow-lg`}
        href={{
          pathname: '/ckk/community/free_board/detail/[articleId]',
          query: {
            articleId: article.id,
          },
        }}>
        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex w-full items-center justify-between gap-2">
            <Tag color="yellow">
              {ARTICLE_CATEGORY_MAP[article.category].emoji}{' '}
              {ARTICLE_CATEGORY_MAP[article.category].name[locale]}
            </Tag>
            <div className="flex gap-2 text-xs font-light text-gray-700">
              <span>
                <FontAwesomeIcon icon={faEye} /> {article?.readCount ?? 0}
              </span>
              <span>
                <FontAwesomeIcon icon={faComment} />{' '}
                {article?.comments?.length ?? 0}
              </span>
              <span>
                <FontAwesomeIcon className="mr-1" icon={faThumbsUp} />
                {article.upvote?.length ?? 0}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <ProfileItem
              displayName={article.author.displayName}
              photoURL={article.author.photoURL}
              size="sm"
              uid={article.author.uid}
              withDisplayName={false}
              withLink={false}
            />
            <div className="w-44 truncate text-sm text-black">
              {article.title[locale]}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default forwardRef(ArticleCardItem)
