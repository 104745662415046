import Link from 'next/link'
import { useRouter } from 'next/router'

import Img from '@/components/common/Img'
import { Position, POSITION_MAP } from '@/types/ckk'
import { Size, Url } from '@/types/shared'

interface Props {
  position: Position
  withText?: boolean
  withLink?: boolean
  size?: Size
  imageStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
  imageClassName?: string
  textClassName?: string
}

const PositionItem: React.FC<Props> = ({
  position,
  size = 'xs',
  withText,
  withLink = true,
  imageStyle,
  textStyle,
  imageClassName,
  textClassName,
}) => {
  const { locale, pathname, query } = useRouter()

  const getStyleFromSize = (_size: Size): React.CSSProperties => {
    switch (_size) {
      case 'xs':
        return {
          width: 20,
          height: 20,
        }
      case 'sm':
        return {
          width: 40,
          height: 40,
          minWidth: 40,
          minHeight: 40,
        }
      case 'md':
        return {
          width: 48,
          height: 48,
          minWidth: 48,
          minHeight: 48,
        }
      default:
        return {
          width: 48,
          height: 48,
          minWidth: 48,
          minHeight: 48,
        }
    }
  }

  const renderImage = () => {
    return (
      <span
        className={`item-image ${imageClassName}`}
        style={imageStyle}
        title={localeName}>
        <Img
          alt={localeName}
          src={image}
          style={{
            ...getStyleFromSize(size),
          }}
        />
      </span>
    )
  }

  const renderText = () => {
    return (
      <span
        className={textClassName}
        style={{
          marginLeft: '0.27rem',
          position: withText ? 'relative' : 'absolute',
          fontSize: withText ? 'inherit' : 0,
          color: '#bd8307',
          ...textStyle,
        }}>
        {localeName}
      </span>
    )
  }

  const { name, image } = POSITION_MAP[position]
  const localeName = name[locale]
  const parsedParamMap = {
    ...(pathname === '/ckk/cookie/dictionary' ? query : {}),
    position:
      pathname === '/ckk/cookie/dictionary' && query.position === position
        ? 'all'
        : position,
  }
  const linkURL: Url = `/ckk/cookie/dictionary?${new URLSearchParams(
    parsedParamMap,
  ).toString()}`

  return withLink ? (
    <>
      <Link href={linkURL}>{renderImage()}</Link>
      {withText && <Link href={linkURL}>{renderText()}</Link>}
    </>
  ) : (
    <>
      {renderImage()}
      {withText && renderText()}
    </>
  )
}

export default PositionItem
