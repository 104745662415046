import {
  ContentEnum,
  Difficulty,
  MiniBaseCookie,
  MinimizeTreasure,
  LocalizedString,
  Topping,
  IComment,
} from '@/types/ckk'
import { FirestoreCommon } from '@/types/firebase'
import { Profile } from '@/types/user'

interface Deck {
  title?: LocalizedString
  cookies: (Omit<MiniBaseCookie, 'toppings'> & {
    toppings: Topping[]
  })[]
  cookieDetails: string[]
  treasures: MinimizeTreasure[]
  treasureDetails: string[]
  description?: LocalizedString
}

export enum DeckArticleCategory {
  GUIDE = 'GUIDE',
  QUESTION = 'QUESTION',
}

export const DECK_ARTICLE_CATEGORY_MAP: Record<
  DeckArticleCategory,
  {
    title: LocalizedString
    emoji: string
  }
> = {
  [DeckArticleCategory.GUIDE]: {
    title: {
      ko: '공략',
      en: 'Guide',
    },
    emoji: '📚',
  },
  [DeckArticleCategory.QUESTION]: {
    title: {
      ko: '질문',
      en: 'Question',
    },
    emoji: '❓',
  },
}

export interface DeckArticle extends FirestoreCommon {
  featured?: boolean
  author: Profile
  title: LocalizedString
  description: LocalizedString
  upvote?: string[]
  downvote?: string[]
  links: string[]
  content: ContentEnum
  decks: Deck[]
  cookies?: (Omit<MiniBaseCookie, 'toppings'> & {
    toppings: Topping[]
  })[]
  cookieDetails: string[]
  treasures?: MinimizeTreasure[]
  treasureDetails: string[]
  comments?: IComment[]
  readCount?: number
  category?: DeckArticleCategory
}

export interface WorldExplorationDeckArticle extends DeckArticle {
  content: ContentEnum.WORLD_EXPLORATION
  difficulty: Difficulty
  episode: number
  stage: number
}
