import { QueryConstraint } from 'firebase/firestore'

import { CommentWityKey, IComment, PostType } from '@/types/ckk'
import {
  createCollectionDoc,
  deleteCollectionDoc,
  getCollection,
  readCollectionDocById,
  updateCollectionDoc,
} from '@/utils/firebase'
import { readProfileByUid } from '@/utils/firebase/profile'

export const COMMENT_PAGE_SIZE = 5

const commentCollectionRef = getCollection('comments')

export const createCommentWithKey = createCollectionDoc<CommentWityKey>({
  collectionRef: commentCollectionRef,
})

export const readComments = async ({
  uid,
  postType,
  postId,
}: {
  uid?: string
  postType?: PostType
  postId?: string
}): Promise<IComment[]> => {
  const { query, where, getDocs, orderBy } = await import('firebase/firestore')
  const commentsWithKey: CommentWityKey[] = []

  const queryConstraints: QueryConstraint[] = []

  if (uid) {
    queryConstraints.push(where('author.uid', '==', uid))
  }

  if (postType) {
    queryConstraints.push(where('postType', '==', postType))
  }

  if (postId) {
    queryConstraints.push(where('postId', '==', postId))
  }
  queryConstraints.push(orderBy('createdAt', 'asc'))

  const q = query(commentCollectionRef, ...queryConstraints)

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((_doc) => {
    const commentWithKey = { id: _doc.id, ..._doc.data() } as CommentWityKey
    commentsWithKey.push(commentWithKey)
  })

  const comments = await Promise.all(
    commentsWithKey.map(async (comment) => {
      const profile = await readProfileByUid({ uid: comment.author.uid })
      return {
        ...comment,
        author: profile,
      }
    }),
  )

  return comments
}

export const readPagedComments = async ({
  uid,
  postType,
  postId,
  createdAt,
  pageSize = COMMENT_PAGE_SIZE,
}: {
  uid?: string
  postType?: PostType
  postId?: string
  createdAt?: string
  pageSize?: number
}): Promise<IComment[]> => {
  const { query, where, getDocs, orderBy, limit, startAfter } = await import(
    'firebase/firestore'
  )

  const commentsWithKey: CommentWityKey[] = []
  const queryConstraints: QueryConstraint[] = []

  if (uid) {
    queryConstraints.push(where('author.uid', '==', uid))
  }
  if (postType) {
    queryConstraints.push(where('postType', '==', postType))
  }
  if (postId) {
    queryConstraints.push(where('postId', '==', postId))
  }
  queryConstraints.push(orderBy('createdAt', 'desc'))
  if (createdAt) {
    queryConstraints.push(startAfter(createdAt))
  }
  queryConstraints.push(limit(pageSize))

  const q = query(commentCollectionRef, ...queryConstraints)

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((_doc) => {
    const commentWithKey = { id: _doc.id, ..._doc.data() } as CommentWityKey
    commentsWithKey.push(commentWithKey)
  })

  const comments = await Promise.all(
    commentsWithKey.map(async (comment) => {
      const profile = await readProfileByUid({ uid: comment.author.uid })
      return {
        ...comment,
        author: profile,
      }
    }),
  )

  return comments
}

export const updateComment = updateCollectionDoc<CommentWityKey>({
  collectionRef: commentCollectionRef,
})

export const readCommentWithKeyById = readCollectionDocById<CommentWityKey>({
  collectionRef: commentCollectionRef,
})

export const deleteComment = deleteCollectionDoc({
  collectionRef: commentCollectionRef,
})
