import Link from 'next/link'
import { useRouter } from 'next/router'

import Img from '@/components/common/Img'
import { CONTENT_MAP, ContentEnum } from '@/types/ckk'
import { Size, Url } from '@/types/shared'
import { sendGAEvent } from '@/utils/gtag'
import { getStyleFromSize } from '@/utils/size'

interface Props {
  content: ContentEnum
  size?: Size
  withText?: boolean
  withLink?: boolean
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
  className?: string
  textClassName?: string
}

function ContentItem({
  size = 'xs',
  style,
  textStyle,
  className,
  textClassName,
  content,
  withText = false,
  withLink = true,
}: Props) {
  const { name, image } = CONTENT_MAP[content]
  const router = useRouter()
  const { locale } = router

  const linkURL: Url =
    content === ContentEnum.KINGDOM_ARENA
      ? '/ckk/gameplay/kingdom_arena'
      : content === ContentEnum.WORLD_EXPLORATION
      ? '/ckk/gameplay/world_exploration'
      : content === ContentEnum.GUILD_BATTLE_RED_VELVET_DRAGON ||
        content === ContentEnum.GUILD_BATTLE_AVATAR_OF_DESTINY ||
        content === ContentEnum.GUILD_BATTLE_LIVING_LICORICE
      ? '/ckk/gameplay/guild_battle'
      : content === ContentEnum.COOKIE_ALLIANCE
      ? '/ckk/gameplay/cookie_alliance'
      : '#'

  const localeName = name[locale]

  const handleClick = () => {
    if (withLink) {
      sendGAEvent({
        category: '토핑 이미지',
        action: `토핑 상세페이지 이동`,
        label: name.ko,
      })
    }
  }

  const renderImage = () => {
    return (
      <Img
        alt={localeName}
        className={`item-image ${className}`}
        src={image.icon}
        style={{ ...getStyleFromSize(size), ...style }}
        title={localeName}
        onClick={handleClick}
      />
    )
  }

  const renderText = () => {
    return (
      <span
        className={`ml-1 text-left text-[#bd8307] ${
          withText ? 'relative text-inherit' : 'absolute text-[0px]'
        } ${textClassName}`}
        style={textStyle}
        title={localeName}
        onClick={handleClick}>
        {localeName}
      </span>
    )
  }

  return withLink ? (
    <>
      <Link href={linkURL} title={localeName}>
        {renderImage()}
      </Link>
      {withText && (
        <Link href={linkURL} title={localeName}>
          {renderText()}
        </Link>
      )}
    </>
  ) : (
    <>
      {renderImage()}
      {withText && renderText()}
    </>
  )
}

export default ContentItem
