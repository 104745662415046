import { ExtendedImageObject, Grade } from '@/types/ckk'

export const CKK_GRADE_MAP: Record<Grade, ExtendedImageObject> = {
  [Grade.BEAST]: {
    name: { ko: '비스트', en: 'Beast' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/64323e99-7adb-4411-d17e-e4b7990d7500/public',
  },
  [Grade.ANCIENT]: {
    name: { ko: '에인션트', en: 'Ancient' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/b80b67b8-dc5c-49e3-07ca-f1673e459100/public',
  },
  [Grade.LEGENDARY]: {
    name: { ko: '레전더리', en: 'Legendary' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/fb2bbed1-186c-4edf-1741-7edb8cdf7100/public',
  },
  [Grade.SUPER_EPIC]: {
    name: { ko: '슈퍼 에픽', en: 'Super Epic' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/ef97da70-b550-428a-c03c-ed4db59a9300/public',
  },
  [Grade.EPIC]: {
    name: { ko: '에픽', en: 'Epic' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/e6cc601e-19ee-421b-e936-9cdd20eaf100/public',
  },
  [Grade.RARE]: {
    name: { ko: '레어', en: 'Rare' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/bb797fd5-b48e-4800-641b-ae363f9c4900/public',
  },
  [Grade.COMMON]: {
    name: { ko: '커먼', en: 'Common' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/1f625a4d-db83-456d-8555-41aa63ce6600/public',
  },
  [Grade.SPECIAL]: {
    name: { ko: '스페셜', en: 'Special' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/dc7567c4-7d16-4017-52c2-4586e7112500/public',
  },
  [Grade.GUEST]: {
    name: { ko: '게스트', en: 'Guest' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/44baf5a2-e73d-41a1-8a06-064264ec7100/public',
  },
  [Grade.DRAGON]: {
    name: { ko: '용', en: 'Dragon' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/9ea3ad41-1df7-4b8e-0e52-3c1f9ac48400/public',
  },
}
