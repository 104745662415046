import React from 'react'

import Flicking from '@egjs/react-flicking'

import DeckArticleCardItem from '@/components/ckk/items/DeckArticleCardItem'
import { DeckArticle } from '@/types/ckk/deck'

interface Props {
  className?: string
  deckArticles: DeckArticle[]
}

function DeckArticleCardFlicking({ deckArticles }: Props) {
  return (
    <Flicking
      bound
      hideBeforeInit
      horizontal
      preventClickOnDrag
      align="prev"
      moveType="snap">
      {deckArticles.map((deckArticle, index) => (
        <DeckArticleCardItem
          key={deckArticle.id}
          deckArticle={deckArticle}
          isFirst={index === 0}
          isLast={index === deckArticles.length - 1}
        />
      ))}
    </Flicking>
  )
}

export default DeckArticleCardFlicking
