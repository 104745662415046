import { GetStaticProps, InferGetStaticPropsType } from 'next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useEffect, useState } from 'react'

import Flicking from '@egjs/react-flicking'
import { Col, Row } from 'antd'
import { useUser, withUser } from 'next-firebase-auth'
import { SSRConfig, useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { UAParser } from 'ua-parser-js'
import { useLocalStorage } from 'usehooks-ts'

import MiddleContentAd from '@/components/adsense/MiddleContentAd'
import AutoSlider from '@/components/AutoSlider'
import Banner from '@/components/ckk/Banner'
import { CookieForCard } from '@/components/ckk/items/CookieCardItem'
import { TreasureForCard } from '@/components/ckk/items/TreasureCardItem'
import RewardItem from '@/components/ckk/pages/coupon/RewardItem'
import ArticleCardFlicking from '@/components/ckk/pages/index/ArticleCardFlicking'
import CookieCardFlicking from '@/components/ckk/pages/index/CookieCardFlicking'
import DeckArticleCardFlicking from '@/components/ckk/pages/index/DeckArticleCardFlicking'
import TreasureCardFlicking from '@/components/ckk/pages/index/TreasureCardFlicking'
import Subtitle from '@/components/ckk/Subtitle'
import Layout from '@/components/layout/Layout'
import {
  ANDROID_APP_BANNER,
  APPLE_APP_BANNER,
  DISCORD_BANNER,
  DONATION_BANNER,
  FEEDBACK_BANNER,
  CKT_KAKAO_OPEN_CHAT_BANNER,
} from '@/constants/ckk/banner'
import { PROMOTIONS } from '@/constants/ckk/promotion'
import { LOCAL_STORAGE_KEY_MAP } from '@/constants/config'
import { BannerTag, MainBanner } from '@/types/banner'
import { Article } from '@/types/ckk'
import { DeckArticle } from '@/types/ckk/deck'
import { Reward } from '@/types/shared'
import { convertCookieToCookieForCard } from '@/utils/ckk/cookie'
import { convertBaseCouponsToCoupons } from '@/utils/ckk/coupon'
import { filterCoupons, getTotalRewardsFromItems } from '@/utils/coupon'
import { readPagedArticles } from '@/utils/firebase/article'
import { readPagedDecks } from '@/utils/firebase/deckArticle'
import { readTreasures } from '@/utils/firebase/treasure'
import firestoreAction from '@/utils/firestore'
import { gradeSorter, releaseDatetimeSorter } from '@/utils/sort'

function convertUndefinedToNull(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  for (const key in obj) {
    if (obj[key] === undefined) {
      obj[key] = null
    } else if (typeof obj[key] === 'object') {
      convertUndefinedToNull(obj[key])
    }
  }

  return obj
}

const FeedbackButton = dynamic(
  () => import('@/components/ckk/FeedbackButton'),
  {
    ssr: false,
  },
)

type Props = InferGetStaticPropsType<typeof getStaticProps>

function CkkHomePage({
  rewards,
  cookies,
  treasures,
  articles,
  deckArticles,
}: Props) {
  const { t } = useTranslation('common')
  const { locale } = useRouter()
  const [isWebview] = useLocalStorage(LOCAL_STORAGE_KEY_MAP.ckk.webview, false)
  const [UA, setUA] = useState<UAParser.IResult>()
  const user = useUser()

  useEffect(() => {
    const nextUA = new UAParser().getResult()
    setUA(nextUA)
  }, [])

  const isLoginedUser = user.firebaseUser !== null
  const isAndroidDevice = UA?.device?.vendor === 'Google'
  const appBanner = isAndroidDevice ? ANDROID_APP_BANNER : APPLE_APP_BANNER
  const MAIN_BANNERS: MainBanner[] = [
    // ...(dayjs().isBefore(expiredDate)
    //   ? [
    //       {
    //         cover: {
    //           url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/7e16f4e8-bc3f-4002-4920-9c3975599c00/public',
    //         },
    //         tags: [Tag.SEASONAL_EVENT],
    //         title: t('pages.holiday_express.title'),
    //         description: t('pages.holiday_express.description'),
    //         link: '/ckk/holiday_express',
    //       },
    //     ]
    //   : []),
    // {
    //   cover: {
    //     url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/bfbb6da2-bc12-4402-8216-68cb4e4f3000/public',
    //   },
    //   tags: [BannerTag.SEASONAL_EVENT],
    //   title: t('pages.event.ox_quiz.title'),
    //   description: t('pages.event.ox_quiz.description'),
    //   link: '/ckk/event/ox_quiz',
    // },
    {
      cover: {
        url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/82a08cb9-52ec-4109-a071-f4e2c3dfa700/public',
      },
      tags: [BannerTag.CONTENT],
      title: t('pages.community_free_board.title'),
      description: t('pages.community_free_board.description'),
      link: '/ckk/community/free_board',
    },
    {
      cover: {
        url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/ea36fbe0-088b-4719-288a-8fadda47d800/public',
      },
      tags: [BannerTag.CONTENT],
      title: t('pages.community_deck.title'),
      description: t('pages.community_deck.description'),
      link: '/ckk/community/deck',
    },
    {
      cover: {
        url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/f479a7f9-ba6d-4863-eda3-0f09263f9b00/public',
      },
      tags: [BannerTag.KINGDOM_AFFAIR, BannerTag.UPDATE],
      title: t('pages.laboratory.title'),
      description: t('pages.laboratory.description'),
      link: '/ckk/kingdom/laboratory',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fl4Wp2%2FbtrLMyRzaoH%2FlVl5vWs3917J5HRV2YQBtk%2Fimg.jpg',
      },
      tags: [BannerTag.TIER],
      title: t('cookie_tier.title'),
      description: t('cookie_tier.desc'),
      link: '/ckk/cookie/tier',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbw9Cch%2FbtrLLxeqXj6%2FHcipzvBsU2sPVEqEJKk3G0%2Fimg.png',
      },
      tags: [BannerTag.UTILITY],
      title: t('pages.coupon_auto_register.title'),
      description: t('pages.coupon_auto_register.description'),
      link: '/ckk/coupon',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbpM7us%2FbtrXypisBuw%2FgWhgg0Tuxzm81IFkMgAX7k%2Fimg.png',
      },
      tags: [BannerTag.UTILITY],
      title: t('pages.cookie.dictionary.title'),
      description: t('pages.cookie.dictionary.description'),
      link: '/ckk/cookie/dictionary',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FkFay9%2FbtrLNvNK24j%2FspEX6EqQxTGkVVkRNkxZBk%2Fimg.jpg',
      },
      tags: [BannerTag.UTILITY],
      title: t('cookie_gacha.title'),
      description: t('cookie_gacha.desc'),
      link: '/ckk/cookie/gacha',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcgh6tO%2FbtrXA9lYw72%2FLkfvpyJt5kvQ03VrzoowIk%2Fimg.jpg',
      },
      tags: [BannerTag.UTILITY],
      title: t('pages.mbti.title'),
      description: t('pages.mbti.desc'),
      link: '/ckk/fun/mbti',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FvlzyM%2Fbtsw0gLsDSR%2FVFaUBzThPgk0lrB6UQu8lk%2Fimg.png',
      },
      tags: [BannerTag.CONTENT],
      title: t('guild_battle.title'),
      description: t('guild_battle.desc'),
      link: '/ckk/gameplay/guild_battle',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FCsroi%2FbtrLPom6Aqg%2FyI1Alab693umGgOZl8XxI1%2Fimg.png',
      },
      tags: [BannerTag.CONTENT],
      title: t('world_exploration.title'),
      description: t('world_exploration.desc'),
      link: '/ckk/gameplay/world_exploration',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FCpxSr%2FbtrFGzB3sOu%2F8ZddljOlEkl41WEWr1p6iK%2Fimg.jpg',
      },
      tags: [BannerTag.UTILITY],
      title: t('cookie_cooldown.title'),
      description: t('cookie_cooldown.desc'),
      link: '/ckk/cookie/cooldown',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FDJg8W%2FbtrLMxryC4T%2FlOprZkTk5jVp0HOeYenov0%2Fimg.jpg',
      },
      tags: [BannerTag.UTILITY],
      title: t('package_efficiency.title'),
      description: t('package_efficiency.desc'),
      link: '/ckk/package',
    },
    {
      cover: {
        url: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FoqeIk%2FbtrXCiJfcMk%2FgkMVLZ02d4VCArOlFXIvB0%2Fimg.png',
      },
      tags: [BannerTag.CONTENT],
      title: t('pages.cookie_alliance.title'),
      description: t('pages.cookie_alliance.description'),
      link: '/ckk/gameplay/cookie_alliance',
    },
  ]

  return (
    <Layout
      visibleAd={{
        anchor: false,
        middle: false,
      }}
      withLayoutDescription={false}
      withLayoutTitle={false}>
      <AutoSlider banners={MAIN_BANNERS} />
      <Row className="w-full pt-4" gutter={[0, 16]}>
        <Col className="px-4" span={24}>
          <Link className="text-inherit" href="/ckk/coupon">
            {rewards?.length ? (
              <Subtitle
                transparent
                extra={<span className="text-gray-500">{t('more')}</span>}
                title={
                  locale === 'ko' ? (
                    isLoginedUser ? (
                      <span>
                        {user.firebaseUser.displayName}
                        님을 위한 쿠폰이 준비되어있습니다!
                      </span>
                    ) : (
                      `이 모든 걸 한 번에 얻을 수 있다고?`
                    )
                  ) : isLoginedUser ? (
                    <span>
                      Hello, {user.firebaseUser.displayName}! Coupons are ready
                      for you!
                    </span>
                  ) : (
                    'Can I get all of this at once?'
                  )
                }
              />
            ) : (
              <Subtitle
                transparent
                extra={
                  <Link
                    className="text-gray-500"
                    href={isWebview ? '/app/ckk/coupon' : '/ckk/coupon'}>
                    {t('go')}
                  </Link>
                }
                title={
                  locale === 'ko'
                    ? `현재 등록가능한 쿠폰 보러가기`
                    : 'Go to see coupons that are currently available for registration'
                }
              />
            )}
          </Link>
        </Col>
        <Col span={24}>
          <div className="w-full">
            <Flicking
              bound
              hideBeforeInit
              horizontal
              align="prev"
              moveType="snap">
              {rewards.map((reward, index) => (
                <div key={reward.currency.name.en}>
                  <RewardItem
                    withAmount
                    className={`ml-4 ${
                      index === rewards.length - 1 ? 'mr-4' : '-mr-1'
                    }`}
                    reward={reward}
                    size="sm"
                  />
                </div>
              ))}
            </Flicking>
          </div>
        </Col>
        <MiddleContentAd className="!px-4" index={0} />
        {/* <Col className="px-4" span={24}>
          <Banner
            {...ARENA_DECK_EVENT_BANNER}
            className="bg-[#c91111] text-white"
          />
        </Col> */}
        <Col className="px-4" span={24}>
          <Subtitle
            transparent
            extra={
              <Link className="text-gray-500" href="/ckk/cookie/dictionary">
                {t('more')}
              </Link>
            }
            title={`${t('recent')} ${t('cookie')}`}
          />
        </Col>
        <Col span={24}>
          <div className="w-full">
            <CookieCardFlicking cookies={cookies} />
          </div>
        </Col>
        <Col className="px-4" span={24}>
          <Banner
            {...(locale === 'ko' ? CKT_KAKAO_OPEN_CHAT_BANNER : DISCORD_BANNER)}
            className={
              locale === 'ko' ? 'bg-[#f9c840]' : 'bg-[#505CEA] text-white'
            }
            image={{
              url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/5ed2accc-3eda-4213-f003-4844cc83bb00/public',
              className: 'translate-y-[30%]',
            }}
          />
        </Col>
        <Col className="px-4" span={24}>
          <Subtitle
            transparent
            extra={
              <Link className="text-gray-500" href="/ckk/community/free_board">
                {t('more')}
              </Link>
            }
            title={`${t('recent')} ${t('article')}`}
          />
        </Col>
        <Col span={24}>
          <div className="w-full">
            <ArticleCardFlicking articles={articles} />
          </div>
        </Col>
        <Col className="px-4" span={24}>
          <Subtitle
            transparent
            extra={
              <Link className="text-gray-500" href="/ckk/community/deck">
                {t('more')}
              </Link>
            }
            title={`${t('recent')} ${t('deck')}`}
          />
        </Col>
        <Col span={24}>
          <div className="w-full">
            <DeckArticleCardFlicking deckArticles={deckArticles} />
          </div>
        </Col>
        {!isWebview && (
          <>
            <MiddleContentAd className="!px-4" index={1} />
            <Col className="px-4" span={24}>
              <Banner
                {...appBanner}
                style={{
                  color: isAndroidDevice ? undefined : '#ffffff',
                  background: isAndroidDevice ? '#3DDC84' : '#000000',
                }}
              />
            </Col>
          </>
        )}
        {!isWebview && locale === 'ko' && (
          <>
            <MiddleContentAd className="!px-4" index={2} />
            <Col className="px-4" span={24}>
              <FeedbackButton
                feedbackFormValues={
                  isLoginedUser && {
                    nickname: `${user.firebaseUser?.displayName} / ${user.firebaseUser?.email}`,
                    content: '',
                  }
                }>
                <Banner {...FEEDBACK_BANNER} className="bg-lime-100" />
              </FeedbackButton>
            </Col>
          </>
        )}
        <Col className="px-4" span={24}>
          <Subtitle
            transparent
            extra={
              <Link className="text-gray-500" href="/ckk/treasure/dictionary">
                {t('more')}
              </Link>
            }
            title={`${t('recent')} ${t('treasure')}`}
          />
        </Col>
        <Col span={24}>
          <div className="flex w-full">
            <TreasureCardFlicking treasures={treasures} />
          </div>
        </Col>
        <Col className="px-4" span={24}>
          <Banner {...DONATION_BANNER} className="bg-yellow-100" />
        </Col>
        <MiddleContentAd className="!px-4" />
      </Row>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps<
  {
    rewards: Reward[]
    cookies: CookieForCard[]
    treasures: TreasureForCard[]
    articles: Article[]
    deckArticles: DeckArticle[]
  } & SSRConfig
> = async ({ locale }) => {
  const baseCoupons = await firestoreAction.coupons.readDocs({
    limit: 5,
  })
  const coupons = await convertBaseCouponsToCoupons(baseCoupons)
  const activeCoupons = filterCoupons({ coupons, status: 'active' })
  const rewards = getTotalRewardsFromItems([...PROMOTIONS, ...activeCoupons])

  const cookies = (
    await firestoreAction.cookies.readDocs({
      limit: 7,
      orderBy: {
        releaseDatetime: 'desc',
      },
    })
  ).map(convertCookieToCookieForCard)

  const treasures = await readTreasures({ withNotification: false })
  const articles = await readPagedArticles({
    withNotification: false,
    pageSize: 7,
  })
  const deckArticles = await readPagedDecks({
    withNotification: false,
    pageSize: 7,
  })

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      rewards,
      cookies: convertUndefinedToNull(cookies),
      treasures: treasures
        .sort(gradeSorter)
        .reverse()
        .sort(releaseDatetimeSorter('asc'))
        .reverse()
        .slice(0, 7)
        .map((treasure) => ({
          name: treasure.name,
          grade: treasure.grade,
          imageWithBackground: treasure.imageWithBackground,
          type: treasure.type,
        })),
      articles,
      deckArticles,
    },
    revalidate: 60 * 60 * 24,
  }
}

export default withUser()(CkkHomePage)
