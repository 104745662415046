import Link from 'next/link'
import { useRouter } from 'next/router'

import Img from '@/components/common/Img'
import { CookieTypeEnum, COOKIE_TYPE_MAP } from '@/types/ckk'
import { Size, Url } from '@/types/shared'
import { getStyleFromSize } from '@/utils/size'

interface Props {
  cookieType: CookieTypeEnum
  withText?: boolean
  withLink?: boolean
  size?: Size
  imageStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
  imageClassName?: string
  textClassName?: string
}

function CookieTypeItem({
  withLink = true,
  cookieType,
  withText,
  size = 'xs',
  imageStyle,
  textStyle,
  imageClassName,
  textClassName,
}: Props) {
  const { locale, pathname, query } = useRouter()

  const renderImage = () => {
    return (
      <span
        className={`item-image keepHTML ${imageClassName}`}
        style={imageStyle}
        title={localeName}>
        <Img
          alt={localeName}
          src={image}
          style={{
            ...getStyleFromSize(size, {
              xs: 16,
              sm: 36,
              md: 40,
            }),
          }}
        />
      </span>
    )
  }

  const renderText = () => {
    return (
      <span
        className={`ml-1  text-[#bd8307] ${textClassName}`}
        style={{
          position: withText ? 'relative' : 'absolute',
          fontSize: withText ? 'inherit' : 0,
          ...textStyle,
        }}>
        {localeName}
      </span>
    )
  }

  const { name, image } = COOKIE_TYPE_MAP[cookieType]
  const localeName = name[locale]
  const parsedParamMap = {
    ...(pathname === '/ckk/cookie/dictionary' ? query : {}),
    cookieType:
      pathname === '/ckk/cookie/dictionary' && query.cookieType === cookieType
        ? 'all'
        : cookieType,
  }
  const url: Url = `/ckk/cookie/dictionary?${new URLSearchParams(
    parsedParamMap,
  ).toString()}`

  return withLink ? (
    <>
      <Link href={url}>{renderImage()}</Link>
      {withText && <Link href={url}>{renderText()}</Link>}
    </>
  ) : (
    <>
      {renderImage()}
      {withText && renderText()}
    </>
  )
}

export default CookieTypeItem
