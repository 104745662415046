import {
  GachaTreasureGrade,
  Grade,
  LocalizedString,
  TreasureGrade,
  TreasureType,
} from '@/types/ckk'

export const EPIC_TREASURE_PROBABILITY = 12 / 22
export const RARE_TREASURE_PROBABILITY = 30 / 7
export const COMMON_TREASURE_PROBABILITY = 58 / 4

export const TREASURE_PROBABILITY_MAP: Record<GachaTreasureGrade, number> = {
  [Grade.EPIC]: EPIC_TREASURE_PROBABILITY,
  [Grade.RARE]: RARE_TREASURE_PROBABILITY,
  [Grade.COMMON]: COMMON_TREASURE_PROBABILITY,
}

export const GACHA_TREASURES = []

export const TREASURE_TYPE_MAP: Record<
  TreasureType,
  { name: LocalizedString; image: string }
> = {
  [TreasureType.ACTIVE]: {
    name: {
      ko: '액티브',
      en: 'Active',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/a9bf1ffc-6229-4456-7329-ff657916e000/public',
  },
  [TreasureType.PASSIVE]: {
    name: {
      ko: '패시브',
      en: 'Passive',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/3e657187-e973-4564-beaf-95be24fbfc00/public',
  },
}

export const TREASURE_GRADE_MAP: Record<TreasureGrade, { image: string }> = {
  [Grade.COMMON]: {
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/f8ec7cc6-ead7-4249-9f98-d717eb350500/public',
  },
  [Grade.RARE]: {
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/f1d8d1c6-b54c-43ca-9694-ad493e6b9400/public',
  },
  [Grade.EPIC]: {
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/5639b9af-2a26-4561-2df6-31cfb7927c00/public',
  },
  [Grade.SPECIAL]: {
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/46fe9f6f-8334-42a5-eb3c-dc8436d8ca00/public',
  },
}

export const TREASURE_TIER_DIGITS = [0, 1, 2, 3, 4, 5]
